import { baseGradients } from "./baseGradients";
import { invisiblegradients } from "./invisiblegradients";

// primary, highlight, border, border2, navbarbg, navbarborder, outsetcomponent, insetcomponent, 
// tablebg, primarybutton, tokeninput, uibackground, mastheadtextgradient, settingstextgradient, dextone


export const baseDark = {
  ...baseGradients, 
  ...invisiblegradients.dark, 
  primary: '#0154fe',
  highlight: '#00ffff',
  border: '#a1a1a1',
  border2: 'rgba(30, 30, 30)',

  navbarbg: '#000', 
  navbarborder: '#a1a1a1',

  outsetComponent: 'linear-gradient(135deg, #090b16, rgba(10, 29, 86, 1), #090b16)',
  insetComponent: `linear-gradient(135deg, #080c14, #000)`,
  uibackground:   `radial-gradient(at 0% 0%, rgba(3, 9, 26, 0.8) 0%, transparent 50%),
  radial-gradient(at 100% 0%, rgba(1, 84, 254, 0.3) 0%, transparent 25%),
  radial-gradient(at 10% 10%, rgba(1, 84, 254, 0.3) 0%, transparent 50%),
  radial-gradient(at 90% 100%, rgba(1, 84, 254, 0.2) 0%, transparent 25%),
  radial-gradient(at 0% 80%, rgba(1, 84, 254, 0.1) 0%, transparent 25%),

  radial-gradient(at 100% 100%, rgba(3, 9, 26, 0.8) 0%, transparent 50%)`, 


  // insetComponent1: 'linear-gradient(135deg, rgba(10, 10, 10, 0.5), rgba(10, 10, 40, 0.75), rgba(10, 10, 10, 0.5))', 
  tablebg: 'linear-gradient(135deg, rgba(17, 17, 17, 0.5), rgba(25, 20, 66, 0.5), rgba(17, 17, 17, 0.25), rgba(25, 20, 35, 0.5))', 



  primarybutton: 'linear-gradient(to bottom, #0154fe, #37C0DF)',
  tokeninput: 'rgba(28, 28, 28, 0.25)', 


  
    mastheadtextgradient: `linear-gradient(
    135deg,
  #0154fe 0%,
  #4d8fff 25%,
  #80b0ff 50%,
  #b3d1ff 75%,
  #e6f2ff 100%
  )`, 
  settingstextgradient: `linear-gradient(
    135deg,
    #0154fe 0%,
    #4d8fff 25%,
    #80b0ff 50%,
    #b3d1ff 75%,
    #e6f2ff 100%
  )`, 




  dextone: {
    100: '#171717', 
    200: '#0F0F0F',
    300: '#0f1420',
    400: '#0c0f19', 
    500: '#090b16', 
    600: '#080a14', 
    700: '#070912', 
    800: '#06080f', 
    900: '#05070d', 
  },
 
};

// primaryrgba: 'rgba(1, 84, 254, 1)',

// primaryrgba: 'rgba(1, 84, 254, 1)',
// highlightrgba: 'rgba(0, 255, 255, 0.25)',
// tealrgba: 'rgb(55, 192, 223, 1)',
