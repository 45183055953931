import { mode } from '@chakra-ui/theme-tools';
import { nichebuttons } from './nichebuttons';

const buttonBase = {
  transition: 'all 0.2s ease',
  fontWeight: '500',
  userSelect: 'none', 
  _focus: { outline: 'none' },
};


export const globalButtons = {
  variants: {
    ...nichebuttons.variants, 
    primary: (props) => ({
      background: 'primarybutton',
      userSelect: 'none', 
      color: 'white',
      _focus: { outline: 'none', boxShadow: 'none' },
      rounded: '14px',
      border: `1px solid`,
      fontWeight: '400 ',
      boxShadow: 'primaryButtonShadow', 
      fontSize: '16px',
      letterSpacing: '-0.5px', 
      borderColor: 'tableborder',
      transition: 'all 0.35s ease-in-out',
      _hover: {
        background: 'slidertrack',
        transition: '0.1s', 
        borderColor: 'rgba(255, 255, 255, 0.5)', 
        boxShadow: 'insetComponentShadow', 
        _disabled: {
          transform: 'none',
          background: 'black',
          cursor: 'not-allowed',
        },
      },
      _active: {
        transform: 'scale(1.01)',
        borderColor: 'text.100',
        transition: '0.1s',
      },

      _disabled: {
        background: 'black',
        opacity: 0.4,
        color: 'whiteAlpha.700',
        cursor: 'not-allowed',
        _active: { bgColor: 'gray.400' },
      },
    }), 

    toggleoff: {
      ...buttonBase, 
      background: 'toggleGradient.400',
      border: '1px', 
      borderColor: 'whiteAlpha.300', 
      padding: '2',
      color: 'popoverButtonText', 
      fontSize: '12px', 
      boxShadow: 'inset 0 1px 4px rgba(0, 0, 0, 0.2)', 
      borderRadius: '12px',  
      _hover: {
        background: 'toggleGradient.200',
        boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.15)',
      }, 
      _active: {
        boxShadow: 'insetComponentShadow', 
        border: '0px', 
      }
    },

    toggleon: (props) => ({
      ...buttonBase, 
      background: 'outsetComponent',
      border: mode('1px solid rgba(30, 30, 30, 0.1)', '0px solid rgba(30, 30, 30, 1)')(props),
      padding: '2',
      color: 'text.400', 
      fontSize: '12px', 
      boxShadow: 'outsetComponentShadow', 
      borderRadius: '12px',  
      _hover: {
        background: 'toggleGradient.400',
        boxShadow: 'insetComponentShadow',
        color: 'text.100', 
      }
    }), 

    // this is the 'common base' for all - then the chain-specific buttons doc will 
    // import and extend the universal/cosmicpawg buttons for chain specific theming

    universalpawg: (props) => ({
      borderWidth: '1px', 
      borderRadius: '14px',
      fontSize: '14px',
      padding: '10px 20px',
      transition: 'all 0.3s ease',
      borderColor: mode('rgba(30, 30, 30, 0.1)', 'rgba(30, 30, 30, 1)')(props), 

      _active: {
        transform: 'scale(0.98)',
      },
      _disabled: {
        opacity: mode('0.9', '0.5')(props)
      }

    }),
    cosmicpawg: (props) => ({
      borderRadius: '12px',
      fontSize: '14px',
      padding: '4px 12px',
      borderWidth: '1px', 
      boxShadow: 'primaryButtonShadow',
      borderColor: mode('rgba(30, 30, 30, 0.1)', 'rgba(30, 30, 30, 1)')(props), 

      transition: 'all 0.2s ease',
      _active: {
        transform: 'translateY(1px)',
        boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06), inset 0 1px 0 rgba(255, 255, 255, 0.1)',
      },
      _disabled: {
        opacity: mode('0.9', '0.5')(props)
      }
  
    }),

   

    secondarytrans: {
      borderRadius: '14px',
      border: '1px solid',
      borderColor: 'primary',
      color: 'text.300',
      fontWeight: 400,
      background: 'black',
      _hover: {
        color: 'text.400',
        background: 'primarybutton',
      },
      _active: {
        transform: 'scale(0.95)',
        transition: '0.1s',
      },
    },
 


  }
};
