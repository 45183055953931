import { mode } from '@chakra-ui/theme-tools';


const buttonBase = {
 transition: 'all 0.2s ease',
 fontWeight: '500',
 _focus: { outline: 'none' },
};

export const nichebuttons = {
  variants: {


  colormodetoggle: (props) => ({
    background: 'insetComponent',

    padding: '1',
    boxShadow: 'outsetComponentShadow', 
    borderRadius: '8px',  
    border: mode('rgba(0, 0, 0, 0.05) !important', 'rgba(255, 255, 255, 0.25) !important')(props),

      _hover: {
        transform: 'scale(1)', 
        transition: '0.1s', 
        boxShadow: 'insetComponentShadow',
        background: 'toggleGradient.400',
        border: mode('1px solid rgba(30, 30, 30, 0.1)', '0px solid rgba(30, 30, 30, 1)')(props),
      }
    }), 

  dropdowntokeninput: {
    background: 'insetComponent',
    borderWidth: '0px',
    borderColor: '#fff', 
    padding: '2',
    boxShadow: 'md', 

    minWidth: '150px', 
    borderRadius: '12px',  
    _hover: {
      background: 'insetComponent', 
      boxShadow: 'insetComponentShadow', 

      transform: 'scale(0.99)', 
      transition: '0.1s', 
    }, 
   
    _active: { outline: 'none', boxShadow: 'insetComponentShadow', },
    _focus: {outline: 'none', boxShadow: 'insetComponentShadow' }

  },

  tokenrow: {
    ...buttonBase, 
    background: 'transparent',
    border: '0px', 
    width: '90% !important', 
    borderRadius: '12px',  
    _hover: {
      background: 'outsetComponent',
      boxShadow: 'insetComponentShadow', 
      transition: '0.01s', 

    }
  },
  
  transparent: {
    borderRadius: '14px',
    border: '0px solid',
    color: 'text.300',
    padding: '8px',
    fontWeight: 500,
    background: 'none',
    _hover: {
      border: '1px solid',
      transform: 'scale(1.00)',
      borderColor: 'primary',
      opacity: 0.8,
    },
  },

  whitebutton: {
    background: 'text.100',
    height: '30px',
    fontSize: '13px',
    color: 'black',
    border: '1px solid',
    borderColor: 'black',
    fontWeight: '400',
    _active: { bgColor: 'black' },
    _focus: { outline: 'none', boxShadow: 'none' },
    rounded: '14px',
    _disabled: {
      transform: 'none',
      background: 'gray.400',
    },
    _hover: {
      opacity: 0.8,
    },
  },

  text: {
    border: '0px solid',
    color: 'text.300',
    fontSize: '12px',
    padding: '0px',
    fontWeight: 500,
    background: 'none',
    _hover: {
      textDecoration: 'underline',
    },
  },
 }}