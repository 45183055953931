
export const globalMenuItemStyles = {
  fontWeight: '600',
  background: 'dextone.100 !important', 
  borderRadius: '24px',
  color: 'text.400 !important',
  paddingY: '4px',
  cursor: 'pointer',
  '&:hover': {
    color: 'text.100 !important',
    background: 'dextone.200 !important', 
  },
};

export const globalDropdownStyles = {
  border: '3px solid !important',
  boxShadow: '48px 48px 96px #111 !important',
  borderColor: 'border !important',
  padding: '12px !important',
  borderRadius: '18px !important',
  background: 'dextone.600 !important', 
};




export const Tooltip =  {
  baseStyle: {
    color: 'text.300',
    bg: 'black',
    borderRadius: '18px',
    boxShadow: '0 0 48px #111',
    padding: '12px',
    border: '1px',
    borderColor: 'border',
  },
}


export const Select = {
  parts: ['field'],
  variants: {
    filled: {
      field: {
        color: 'white',
        bgColor: 'text.100',
        borderColor: 'transparent',
        _hover: {
          borderColor: 'text.100',
          bgColor: 'grayAlpha.500',
        },
        _focus: {
          bgColor: 'grayAlpha.500',
        },
      },
    },
  },
};


export const Alert = {
  parts: ['container'],
  baseStyle: {
    container: {
      borderRadius: 'md',
      alignItems: 'flex-start',
    },
  },
}

export const Skeleton = { 
  baseStyle: {
    borderRadius: 'md',
  },
  defaultProps: {
    startColor: 'gray.400',
    endColor: 'text.100',
  },
}





// export const Tabs = {
//   parts: ['tab'],
//   baseStyle: {
//     tab: {
//       _focus: {
//         boxShadow: 'none',
//       },
//     },
//   },
// };
