import { baseGradients } from "./baseGradients";
import { invisiblegradients } from "./invisiblegradients";
1
export const baseLight = {
  ...baseGradients, 
  ...invisiblegradients.light, 
  primary: '#0154fe',
  highlight: '#00ffff',

  border: '#a1a1a1',
  border2: 'rgba(30, 30, 30)',

  navbarborder: 'transparent', 
  navbarbg: 'transparent', 
  tablebg:    'rgba(245, 242, 244, 1)', 

  layoutbg:     
   `
      radial-gradient(
        circle at 50% 0%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0.1) 75%,
        rgba(255, 255, 255, 0) 100%
      ),
      linear-gradient(
        to bottom right,
        rgba(1, 84, 254, 0.15),
        rgba(245, 242, 244, 0.8), 
        rgba(1, 84, 254, 0.25),
        rgba(245, 242, 244, 0.8), 
        rgba(1, 84, 254, 0.15)
      ),
 
      #f0f0f0
    `, 
    
  
  
  outsetComponent: 'linear-gradient(#fffff0, rgba(255, 250, 245, 1))',
  insetComponent: 'linear-gradient(135deg, rgba(255, 253, 245, 1), rgba(255, 245, 240, 1))',

  // layoutbg: `linear-gradient(to bottom right, rgba(255, 253, 245, 1) 0%, rgba(255, 248, 237, 1) 50%,   rgba(255, 247, 235, 0.8) 100%)`, 

  uibackground: 'rgba(255, 248, 245)', 
  // uibackground: 'linear-gradient(135deg, rgba(249, 246, 238, 1), #DEE4EA, #fffff0)', 



  settingstextgradient: 'linear-gradient(135deg, #0154fe 0%, #4d8fff 100%) ', 
  mastheadtextgradient: `linear-gradient(
    135deg,
      #0154fe 0%,
      #4d8fff 25%,
      #80b0ff 70%,
      #b3d1ff 100%
  )`, 

  dextone: {
    100: '#0B76FF',
    150: '#2585FF',
    200: '#3F94FF',
    250: '#59A3FF',
    300: '#73B2FF',
    400: '#8DC1FF',
    500: '#A7D1FF',
    600: '#C1E0FF',
    700: '#D6EBFF',
    800: '#E6F3FF',
    900: '#F0F8FF'
},

};
