'use client';

import React from 'react';
import { Flex } from '@chakra-ui/react';
import NavbarLink from '../../components/NavbarLink';
import Link from 'next/link';
import DropdownLinksMenu from '../../components/DropdownLinksMenu';
import { usePathname } from 'next/navigation';
import { getNavItems } from '~/lib/global/ChainConfig';
import useIsLarge from '~/app/components/wrapper/useIsLarge';
export default function NavbarDesktop() {
  const pathname = usePathname();
  const navItems = getNavItems();
  const isLarge = useIsLarge();
  return <>
      {isLarge && <Flex margin="0px" zIndex="4" height="100%">
          <Flex height="100%" alignItems="flex-end" justifyContent="flex-start" style={{
        cursor: 'pointer'
      }}>
            <Flex align="flex-end" gap="48px">
              {navItems.map(item => <NavbarLink key={item.path} selected={pathname === item.path}>
                  <Link href={item.path}>{item.name}</Link>
                </NavbarLink>)}
              <DropdownLinksMenu />
            </Flex>
          </Flex>
        </Flex>}
    </>;
}