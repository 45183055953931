'use client';

import { TickerItem } from './TickerItem';
import { Flex } from '@chakra-ui/react';
import Marquee from 'react-fast-marquee';
import { useChainId } from 'wagmi';
import { useGetProtocolDataQuery } from '~/apollo/generated/graphql-codegen-generated';
import useNetworkConfig from '~/lib/global/useNetworkConfig';
import { getTickerTokens } from '~/lib/global/ChainConfig';
import { useGetTokens } from '~/lib/global/useToken';
export function SubNavTicker() {
  const networkConfig = useNetworkConfig();
  const chainId = useChainId() || networkConfig.chainId;
  const {
    data,
    loading
  } = useGetProtocolDataQuery({
    variables: {
      chainId
    },
    fetchPolicy: 'cache-first'
  });
  const {
    priceForAmount
  } = useGetTokens();
  const tokensToDisplay = getTickerTokens();
  const tokensWithPrices = tokensToDisplay.map(token => {
    const rawPrice = priceForAmount({
      amount: token.amount,
      address: token.address
    });
    const price = rawPrice ? Number(rawPrice) : undefined;
    const isLoading = loading && (price === undefined || isNaN(price) || price === 0);
    return {
      ...token,
      price,
      loading: isLoading
    };
  });
  return <Flex ml="2" align="center" overflow="hidden" data-sentry-element="Flex" data-sentry-component="SubNavTicker" data-sentry-source-file="index.tsx">
      <Marquee speed={20} pauseOnHover gradient={false} style={{
      width: '250px',
      overflow: 'hidden'
    }} data-sentry-element="Marquee" data-sentry-source-file="index.tsx">
        {tokensWithPrices.map((token, index) => <Flex key={index} mx="3">
            <TickerItem loading={token.loading} token={token} />
          </Flex>)}
      </Marquee>
    </Flex>;
}