
import { modeTheme } from './mode/modeTheme';
import { optimismTheme } from './optimism/optimismTheme';
import { baseTheme } from './base/baseTheme';
import { ThemeConfig } from '@chakra-ui/react';


export const themesByChainId = {
  '8453': baseTheme,
  '34443': modeTheme,
  '10': optimismTheme,
};

export const config: ThemeConfig = {
  initialColorMode: 'dark' as const,
  useSystemColorMode: false,
};