'use client';

import { Box, BoxProps, useTheme as useChakraTheme } from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/react';
import { useTheme } from 'next-themes';
interface Props extends BoxProps {}
export function SubNavBox({
  children,
  ...rest
}: Props) {
  const chakraTheme = useChakraTheme();
  const {
    theme
  } = useTheme();
  const {
    colorMode
  } = useColorMode();
  const isDarkMode = colorMode === 'dark' || theme === 'dark';
  return <Box border="1px" boxShadow={isDarkMode ? '0 0 12px #000' : 'none'} borderTop="0px" background={isDarkMode ? 'insetComponent' : 'insetComponent'}
  // _hover={{ bg: isDarkMode ? 'gray.850' : 'gray.100' }}
  borderColor="tableborder" borderRadius={isDarkMode ? '18px' : '8px'} borderTopRightRadius="0px" borderTopLeftRadius="0px" marginTop="-1px" zIndex="1" {...rest} data-sentry-element="Box" data-sentry-component="SubNavBox" data-sentry-source-file="SubNavBox.tsx">
      {children}
    </Box>;
}