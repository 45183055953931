'use client';

import React, { useState } from 'react';
import { Flex, HStack, PlacementWithLogical, Popover, PopoverContent, PopoverTrigger as OrigPopoverTrigger, Text, TextProps, Divider } from '@chakra-ui/react';
import { ChevronDown } from 'react-feather';
import NavbarLink from '../../nav/components/NavbarLink';
import Link from 'next/link';
import SocialFooter from '../../nav/components/SocialLinks';
import { usePathname } from 'next/navigation';
import useNetworkConfig from '~/lib/global/useNetworkConfig';
interface Props {
  textProps?: TextProps;
  placement?: PlacementWithLogical;
}
function PopoverLinks({
  textProps,
  placement
}: Props) {
  const pathname = usePathname();
  const networkConfig = useNetworkConfig();
  const [isOpen, setIsOpen] = useState(false);
  const PopoverTrigger: React.FC<{
    children: React.ReactNode;
  }> = OrigPopoverTrigger;
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleMouseEnter = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };
  const handleMouseLeave = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };
  return <Popover trigger="hover" placement={placement} isOpen={isOpen} onClose={() => setIsOpen(false)} data-sentry-element="Popover" data-sentry-component="PopoverLinks" data-sentry-source-file="PopoverLinks.tsx">
      <HStack data-sentry-element="HStack" data-sentry-source-file="PopoverLinks.tsx">
        <PopoverTrigger data-sentry-element="PopoverTrigger" data-sentry-source-file="PopoverLinks.tsx">
          <Flex borderRadius="12px" borderColor="#222" p="2px" onClick={handleToggle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} data-sentry-element="Flex" data-sentry-source-file="PopoverLinks.tsx">
            <ChevronDown color="#ccc" style={{
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.2s'
          }} data-sentry-element="ChevronDown" data-sentry-source-file="PopoverLinks.tsx" />
          </Flex>
        </PopoverTrigger>
      </HStack>
      <PopoverContent padding="4" height="auto" borderRadius="24px" mb="8px" mr="8px" alignItems="flex-start" justifyContent="space-between" bgColor="text.800" border="2px solid" borderColor="border" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} data-sentry-element="PopoverContent" data-sentry-source-file="PopoverLinks.tsx">
        <Flex direction="column" w="100%" align="flex-start" justify="flex-start" data-sentry-element="Flex" data-sentry-source-file="PopoverLinks.tsx">
          {/* <NavbarLink selected={pathname === '/xtoken'}>
            <Link href="/xtoken">xToken</Link>
           </NavbarLink> */}
          <NavbarLink selected={pathname === '/locker'} data-sentry-element="NavbarLink" data-sentry-source-file="PopoverLinks.tsx">
            <Link href="/locker" data-sentry-element="Link" data-sentry-source-file="PopoverLinks.tsx">Token Locker</Link>
          </NavbarLink>
          <NavbarLink data-sentry-element="NavbarLink" data-sentry-source-file="PopoverLinks.tsx">
            {networkConfig.bridgeExternal1 && <Link href={networkConfig.bridgeExternal1.link} target="_blank" rel="noopener noreferrer">
                {networkConfig.bridgeExternal1.name}
              </Link>}
          </NavbarLink>
          <NavbarLink data-sentry-element="NavbarLink" data-sentry-source-file="PopoverLinks.tsx">
            {networkConfig.bridgeExternal2 && <Link href={networkConfig.bridgeExternal2.link} target="_blank" rel="noopener noreferrer">
                {networkConfig.bridgeExternal2.name}
              </Link>}
          </NavbarLink>

          <Divider pt="2" data-sentry-element="Divider" data-sentry-source-file="PopoverLinks.tsx" />
          <Divider pb="2" data-sentry-element="Divider" data-sentry-source-file="PopoverLinks.tsx" />
        </Flex>
        <Flex direction="column" w="100%" align="flex-start" justify="flex-start" py="2" data-sentry-element="Flex" data-sentry-source-file="PopoverLinks.tsx">
          <Text fontSize="24px" color="text.100" py="2" data-sentry-element="Text" data-sentry-source-file="PopoverLinks.tsx">
            Socials
          </Text>
          <Flex w="100%" px="3" justify="space-between" data-sentry-element="Flex" data-sentry-source-file="PopoverLinks.tsx">
            <SocialFooter data-sentry-element="SocialFooter" data-sentry-source-file="PopoverLinks.tsx" />
          </Flex>
          <Divider pt="2" data-sentry-element="Divider" data-sentry-source-file="PopoverLinks.tsx" />
          <Divider pb="2" data-sentry-element="Divider" data-sentry-source-file="PopoverLinks.tsx" />
        </Flex>

        <Flex direction="column" w="100%" align="flex-start" h="100%" justify="flex-start" py="2" data-sentry-element="Flex" data-sentry-source-file="PopoverLinks.tsx">
          <Link href={networkConfig.socialLinks.documents} data-sentry-element="Link" data-sentry-source-file="PopoverLinks.tsx">
            <Text fontSize="24px" color="text.100" data-sentry-element="Text" data-sentry-source-file="PopoverLinks.tsx">
              Documentation
            </Text>
          </Link>

          <Divider py="2" data-sentry-element="Divider" data-sentry-source-file="PopoverLinks.tsx" />
        </Flex>
      </PopoverContent>
    </Popover>;
}
export default PopoverLinks;