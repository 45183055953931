'use client';

import { Text, Flex, Divider, Box } from '@chakra-ui/react';
import numeral from 'numeral';
import { useGetProtocolDataQuery } from '~/apollo/generated/graphql-codegen-generated';
import { useChainId } from 'wagmi';
import { BaseNetworkConfig } from '~/lib/config/base-config';
function StatBox({
  headerLabel,
  children
}) {
  return <Flex flexDirection="column" my="2" data-sentry-element="Flex" data-sentry-component="StatBox" data-sentry-source-file="ProtocolVolume.tsx">
      <Box borderBottomWidth="0px" borderColor="darkborder" p="4px" data-sentry-element="Box" data-sentry-source-file="ProtocolVolume.tsx">
        <Flex justifyContent="center" mb="5px" data-sentry-element="Flex" data-sentry-source-file="ProtocolVolume.tsx">
          <Text fontWeight={500} fontSize={{
          base: '14px',
          lg: '16px'
        }} textDecoration="" data-sentry-element="Text" data-sentry-source-file="ProtocolVolume.tsx">
            {headerLabel}
          </Text>
        </Flex>
        {children}
      </Box>
    </Flex>;
}
interface StatLineProps {
  label: string;
  value: string;
}
function StatLine({
  label,
  value
}: StatLineProps) {
  return <Flex justifyContent="space-between" data-sentry-element="Flex" data-sentry-component="StatLine" data-sentry-source-file="ProtocolVolume.tsx">
      <Text fontSize={{
      base: 'sm',
      lg: '14px'
    }} data-sentry-element="Text" data-sentry-source-file="ProtocolVolume.tsx">{label}</Text>
      <Text fontSize={{
      base: 'sm',
      lg: '14px'
    }} data-sentry-element="Text" data-sentry-source-file="ProtocolVolume.tsx">{value}</Text>
    </Flex>;
}
export function ProtocolVolume() {
  const chainId = useChainId() || BaseNetworkConfig.chainId;
  const {
    data,
    loading
  } = useGetProtocolDataQuery({
    variables: {
      chainId
    },
    fetchPolicy: 'cache-first'
  });
  const protocolData = data?.protocolData;
  return <>
      <StatBox headerLabel="Volume (24h)" data-sentry-element="StatBox" data-sentry-source-file="ProtocolVolume.tsx">
        <StatLine label="V2:" value={protocolData?.v2TotalVolume} data-sentry-element="StatLine" data-sentry-source-file="ProtocolVolume.tsx" />
        <Divider data-sentry-element="Divider" data-sentry-source-file="ProtocolVolume.tsx" />
        <StatLine label="V3:" value={protocolData?.v3TotalVolume} data-sentry-element="StatLine" data-sentry-source-file="ProtocolVolume.tsx" />
        <Divider data-sentry-element="Divider" data-sentry-source-file="ProtocolVolume.tsx" />
      </StatBox>

      <StatBox headerLabel="All Time" data-sentry-element="StatBox" data-sentry-source-file="ProtocolVolume.tsx">
        <StatLine label="Volume:" value={numeral(protocolData?.allTimeV3?.totalVolumeUSD + protocolData?.allTimeV2?.totalVolumeUSD).format('$0,00').toUpperCase() || '0'} data-sentry-element="StatLine" data-sentry-source-file="ProtocolVolume.tsx" />
        <Divider data-sentry-element="Divider" data-sentry-source-file="ProtocolVolume.tsx" />
        <StatLine label="Fees:" value={numeral(protocolData?.allTimeV2?.totalFeesUSD + protocolData?.allTimeV3?.totalFeesUSD).format('$0,00') || '0'} data-sentry-element="StatLine" data-sentry-source-file="ProtocolVolume.tsx" />
        <Divider data-sentry-element="Divider" data-sentry-source-file="ProtocolVolume.tsx" />
      </StatBox>

      <StatBox headerLabel="All Time (V2)" data-sentry-element="StatBox" data-sentry-source-file="ProtocolVolume.tsx">
        <StatLine label="Volume:" value={numeral(protocolData?.allTimeV2?.totalVolumeUSD).format('$0,00') || '0'} data-sentry-element="StatLine" data-sentry-source-file="ProtocolVolume.tsx" />
        <Divider data-sentry-element="Divider" data-sentry-source-file="ProtocolVolume.tsx" />
        <StatLine label="Fees:" value={numeral(protocolData?.allTimeV2?.totalFeesUSD).format('$0,00') || '0'} data-sentry-element="StatLine" data-sentry-source-file="ProtocolVolume.tsx" />
        <Divider data-sentry-element="Divider" data-sentry-source-file="ProtocolVolume.tsx" />
      </StatBox>

      <StatBox headerLabel="All Time (V3)" data-sentry-element="StatBox" data-sentry-source-file="ProtocolVolume.tsx">
        <StatLine label="Volume:" value={numeral(protocolData?.allTimeV3?.totalVolumeUSD).format('$0,00') || '0'} data-sentry-element="StatLine" data-sentry-source-file="ProtocolVolume.tsx" />
        <Divider data-sentry-element="Divider" data-sentry-source-file="ProtocolVolume.tsx" />
        <StatLine label="Fees:" value={numeral(protocolData?.allTimeV3?.totalFeesUSD).format('$0,00') || '0'} data-sentry-element="StatLine" data-sentry-source-file="ProtocolVolume.tsx" />
        <Divider data-sentry-element="Divider" data-sentry-source-file="ProtocolVolume.tsx" />
      </StatBox>
    </>;
}