'use client';

import { Box, Flex, Link, Text, useTheme } from '@chakra-ui/react';
import DiscordSimple from '~/app/components/icons/Simple/DiscordSimple';
import TelegramSimple from '~/app/components/icons/Simple/TelegramSimple';
import TwitterSimple from '~/app/components/icons/Simple/TwitterSimple';
import useNetworkConfig from '~/lib/global/useNetworkConfig';
import Image from 'next/image';
import RedditSimple from '~/app/components/icons/Simple/RedditSimple';
import MediumSimple from '~/app/components/icons/Simple/MediumSimple';
const DesktopFooter = () => {
  const networkConfig = useNetworkConfig();
  const theme = useTheme();
  return <Flex align="center" bg="nav" color="text.100" p="2" borderTopWidth="1px" data-sentry-element="Flex" data-sentry-component="DesktopFooter" data-sentry-source-file="Footer.tsx">
      <Flex w="100%" justify="space-between" data-sentry-element="Flex" data-sentry-source-file="Footer.tsx">
        <Flex bg="" align="center" data-sentry-element="Flex" data-sentry-source-file="Footer.tsx">
          <Box width="40px" height="40px" position="relative" data-sentry-element="Box" data-sentry-source-file="Footer.tsx">
            <Image src={networkConfig.dexIconInRepo} fill alt="App Icon" sizes="40px" data-sentry-element="Image" data-sentry-source-file="Footer.tsx" />
          </Box>
          <Flex direction="column" ml="2" justify="center" align="flex-start" data-sentry-element="Flex" data-sentry-source-file="Footer.tsx">
            <Flex data-sentry-element="Flex" data-sentry-source-file="Footer.tsx">
              <Text className="text1" data-sentry-element="Text" data-sentry-source-file="Footer.tsx">{networkConfig.appName}</Text>
              <Text color="primary" className="text1" data-sentry-element="Text" data-sentry-source-file="Footer.tsx">
                .
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex align="center" direction="column" justify="center" data-sentry-element="Flex" data-sentry-source-file="Footer.tsx">
          <Text className="text6" data-sentry-element="Text" data-sentry-source-file="Footer.tsx">
            The premier DEX onchain with{' '}
            <span style={{
            color: theme.colors.primary
          }}>{networkConfig.networkShortName}</span>
          </Text>
          <Text className="bodytext" data-sentry-element="Text" data-sentry-source-file="Footer.tsx">© 2024</Text>
        </Flex>
        <Flex justify="space-between" align="center" data-sentry-element="Flex" data-sentry-source-file="Footer.tsx">
          <Flex data-sentry-element="Flex" data-sentry-source-file="Footer.tsx">
            <Link href={networkConfig.socialLinks.discord} mx={2} isExternal data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
              <DiscordSimple color="#fff" data-sentry-element="DiscordSimple" data-sentry-source-file="Footer.tsx" />
            </Link>
            <Link href={networkConfig.socialLinks.telegram} mx={2} isExternal data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
              <TelegramSimple color="#fff" data-sentry-element="TelegramSimple" data-sentry-source-file="Footer.tsx" />
            </Link>
            <Link href={networkConfig.socialLinks.twitter} mx={2} isExternal data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
              <TwitterSimple color="#fff" data-sentry-element="TwitterSimple" data-sentry-source-file="Footer.tsx" />
            </Link>
            <Link href={networkConfig.socialLinks.reddit} mx={2} isExternal data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
              <RedditSimple color="#fff" data-sentry-element="RedditSimple" data-sentry-source-file="Footer.tsx" />
            </Link>
            <Link href={networkConfig.socialLinks.medium} mx={2} isExternal data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
              <MediumSimple color="#fff" data-sentry-element="MediumSimple" data-sentry-source-file="Footer.tsx" />
            </Link>
          </Flex>
        </Flex>
        {/* <Flex justify="space-between" align="center" mb={4}>
          <Flex>
            <Link
              mx={2}
              color="gray.300"
              _hover={{ color: theme.colors.primary, cursor: 'pointer' }}
              href={networkConfig.socialLinks.documents}
            >
              Documentation
            </Link>
                </Flex>
         </Flex> */}
      </Flex>
    </Flex>;
};
export default DesktopFooter;