import { modeGradients } from "./modeGradients";


export const modeLight = {
  ...modeGradients, 
  primary: '#dffe00',
  highlight: '#FFF',

  border: '#a1a1a1',
  border2: 'rgba(51,51,51,0.2)',

  navbarbg: 'transparent', 
  navbarborder: 'transparent', 

  outsetComponent: 'linear-gradient(135deg, #E1DED7, #EBE8E1)',
  insetComponent: 'linear-gradient(#fffff0, #f5f5f5)',

  // swapcard1 swapcard2
  // token input
  settingstextgradient: 'linear-gradient(to bottom right, #60FE00, #DFFE00)',
  mastheadtextgradient: 'linear-gradient(to left, #DFFE00, #60FE00)',

  uibackground: `
    radial-gradient(ellipse at 25% 50%, rgb(128, 122, 0, 0.15) 0%, transparent 25%),
    linear-gradient(to right, #EFEDE6, #EBE8E0)`,

  dextone: {
    100: '#DFFE00',  
    150: '#E5FF33',
    200: '#EBFF66',
    250: '#F1FF99',
    300: '#F7FFCC',
    400: '#FCFFE5',
    500: '#FDFFED',
    600: '#FEFFF4',
    700: '#FEFFFA',  
    800: '#FEFFFD',  
    900: '#FFFFFF'   
}

};
