import { modeGradients } from "./modeGradients";

export const modeDark = {
  ...modeGradients, 
  primary: '#dffe00',
  highlight: '#FFF',

  border: '#a1a1a1',
  border2: 'rgba(51,51,51,0.2)',

  navbarbg: 'transparent', 
  navbarborder: '#a1a1a1', 

  outsetComponent: 'linear-gradient(135deg, #233142, #303242)', 
  insetComponent: 'linear-gradient(135deg, #1A1E23, #14181E)', 
  
  swapcard1: '#000', 
  swapcard2: '#000', 
  // this is used currently for table glow class. adjust. 

  tokeninput: 'rgba(0, 0, 0, 1)',

  uibackground: 'linear-gradient(to bottom, #111, #191919, #111)', 
  
  settingstextgradient: 'linear-gradient(to left, #DFFE00, #60FE00)',
  mastheadtextgradient: 'linear-gradient(to left, #DFFE00, #60FE00)',
  

  // needs a mastheadtextgradient linear gradient like op/base on dark
  dextone: {
    100: '#171717', 
    200: '#0F0F0F',
    300: '#0f1420',
    400: '#0c0f19', 
    500: '#090b16', 
    600: '#080a14', 
    700: '#070912', 
    800: '#06080f', 
    900: '#05070d', 
},
};
