import React, { useMemo } from 'react';
import useNetworkConfig from '~/lib/global/useNetworkConfig';
import { useTheme as useNextTheme } from 'next-themes';
import { chakraTheme } from '~/styles/chakraTheme';
import { ChakraProvider } from '@chakra-ui/react';
export const ThemedChakraProvider: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const networkConfig = useNetworkConfig();
  const chainId = networkConfig?.chainId.toString();
  const {
    resolvedTheme
  } = useNextTheme();
  const colorMode: 'light' | 'dark' = resolvedTheme === 'dark' ? 'dark' : 'light';
  const dynamicTheme = useMemo(() => {
    return chakraTheme(chainId || 'default', colorMode);
  }, [chainId, colorMode]);
  return <ChakraProvider theme={dynamicTheme} data-sentry-element="ChakraProvider" data-sentry-component="ThemedChakraProvider" data-sentry-source-file="ThemedChakraProvider.tsx">{children}</ChakraProvider>;
};