export const modeGradients = {
 
 
 dexgradient: {
  100: 'linear-gradient(to right, #DFFE00, #60FE00)',
  200: 'linear-gradient(to right, #DFFE00, #60FE00)',
  300: 'linear-gradient(to bottom, rgba(223, 254, 0, 0.75), rgba(223, 254, 0, 0.75))',
},

 primarybutton: 'linear-gradient(135deg, #DFFE00, #60FE00)',
 slidertrack: 'linear-gradient(to left, #DFFE00, #60FE00)',
 scrollbar: 'linear-gradient(to right, #DFFE00, #60FE00)',
 buttongroup: 'linear-gradient(to right, #DFFE00, #60FE00)',

 darkgradient: {
  100: 'linear-gradient(to bottom, #070707, #191919, #070707)',
  200: 'linear-gradient(to left, #020202, #191919, #020202)',
  300: 'linear-gradient(to bottom, #020202, #191919, #020202)',
  400: 'linear-gradient(to bottom, #111, #191919, #111)',
  500: 'linear-gradient(to top, #0A0A0C, #020202)',
  600: 'linear-gradient(to top, #1A1B1F, #0E0F11)',
  700: 'linear-gradient(to bottom, rgba(41, 41, 41, 0.85), rgba(2, 2, 2, 0.5))',
  900: ' linear-gradient(to bottom, #000, #111)',
},
cardgradient: {
  100: 'linear-gradient(to bottom, #020202, #191919, #020202)',
  200: 'linear-gradient(to top, #1A1B1F, #0E0F11)',
  700: 'linear-gradient(#000,#111)', 
},
}