'use client';

import '@rainbow-me/rainbowkit/styles.css';
import { useApollo } from '~/apollo/client';
import { ApolloProvider } from '@apollo/client';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import { wagmiConfig } from '~/lib/global/connection';
import { WalletUserAvatar } from '../components/avatar/WalletUserAvatar';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from '~/state';
import { ThemedChakraProvider } from './ThemedChakraProvider';
import AppToast from '~/app/components/toast/AppToast';
// import { I18nProvider } from '@lingui/react';
// import { i18n } from '@lingui/core';
import Compose from '~/components/providers/Compose';
// import { messages } from '~/locales/en/messages';
import { ProviderWithProps } from '~/components/providers/Compose';
import { TokensProvider } from '~/lib/global/useToken';
import { ThemeProvider as ColorThemeProvider } from 'next-themes';
import 'animate.css';
import { CHAIN_MAIN_NAME } from '~/constants';

// i18n.load('en', messages);
// i18n.activate('en');

const queryClient = new QueryClient();
export default function Providers({
  children
}) {
  const client = useApollo();
  if (!client) {
    return <div>Loading...</div>;
  }
  const AppProviders: ProviderWithProps[] = [[TokensProvider, {}]
  // [UserDataProvider, {}],
  // [BlockNumberProvider, {}],
  ];
  return (
    // <I18nProvider i18n={i18n}>
    <ReduxProvider store={store} data-sentry-element="ReduxProvider" data-sentry-component="Providers" data-sentry-source-file="providers.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="providers.tsx">
        <WagmiProvider config={wagmiConfig} data-sentry-element="WagmiProvider" data-sentry-source-file="providers.tsx">
          <RainbowKitProvider coolMode showRecentTransactions={true} appInfo={{
            appName: CHAIN_MAIN_NAME,
            learnMoreUrl: 'https://medium.com/blockchain/crypto-wallets-explained-f9199e621366'
          }} theme={darkTheme()} avatar={() => <WalletUserAvatar />} data-sentry-element="RainbowKitProvider" data-sentry-source-file="providers.tsx">
            <ColorThemeProvider defaultTheme="dark" attribute="class" enableSystem={false} data-sentry-element="ColorThemeProvider" data-sentry-source-file="providers.tsx">
              <ThemedChakraProvider data-sentry-element="ThemedChakraProvider" data-sentry-source-file="providers.tsx">
                <ApolloProvider client={client} data-sentry-element="ApolloProvider" data-sentry-source-file="providers.tsx">
                  <AppToast data-sentry-element="AppToast" data-sentry-source-file="providers.tsx">
                    <Compose providers={AppProviders} data-sentry-element="Compose" data-sentry-source-file="providers.tsx">{children}</Compose>
                  </AppToast>
                </ApolloProvider>
              </ThemedChakraProvider>
            </ColorThemeProvider>
          </RainbowKitProvider>
        </WagmiProvider>
      </QueryClientProvider>
    </ReduxProvider>
    // </I18nProvider>
  );
}