import { HStack, Skeleton, Text } from '@chakra-ui/react';
import TokenAvatar from '~/app/components/token/TokenAvatar';
import numeral from 'numeral';
export function TickerItem({
  loading,
  token
}) {
  return loading ? <Skeleton height="16px" width="54px" /> : <HStack spacing="3px">
      <TokenAvatar alt={token.name} address={token.address} size={14} />
      <Text fontSize="14px" color="text.100">
        {numeral(token.price).format(token.format)}
      </Text>
    </HStack>;
}