import { mode } from '@chakra-ui/theme-tools';
import { globalButtons } from '../../global/buttons/mainbuttons';
// bg: mode('porcelain', 'black')(props),

export const baseButtons = {
  variants: {
    universalpawg: (props) => ({
      ...globalButtons.variants.universalpawg(props), 
      background: mode('linear-gradient(to bottom right, rgba(0, 0, 255), #fff)', 'linear-gradient(135deg, #001a33, #003366)')(props), 
      color: 'text.100',
      borderColor: 'primary', 
      fontWeight: '500',
      letterSpacing: '0px',
      boxShadow: '0 0 6px rgba(0, 255, 255, 0.3), inset 0 0 5px rgba(0, 255, 255, 0.2)',
      _hover: {
        background: 'linear-gradient(135deg, #002b4d, #004080)',
        boxShadow: '0 0 15px rgba(0, 255, 255, 0.4), inset 0 0 8px rgba(0, 255, 255, 0.3)',
        transform: 'scale(0.99)', 
      },
      _disabled: {
        background: 'linear-gradient(135deg, #f0f0f0, #004080)', // this would be good on full opac
        color: '#555', 
      }
    }), 


    cosmicpawg: (props) => ({
      ...globalButtons.variants.cosmicpawg(props), 
      background: mode('linear-gradient(to bottom right, blue, #fff)', 'linear-gradient(135deg, rgba(0, 0, 255, 0.1), #000)')(props), 
      color: '#fff',
      border: '1px solid', 
      borderColor: '#0066cc',
      fontWeight: '500', 
      letterSpacing: '0px',
      boxShadow: '0 0 5px rgba(255, 255, 255, 0.3), inset 0 0 5px rgba(0, 0, 255, 0.2)',
      _hover: {
        background: 'linear-gradient(135deg, rgba(0, 0, 255, 0.2), #000)',
        boxShadow: '0 0 15px rgba(255, 255, 255, 0.4), inset 0 0 8px rgba(0, 0, 255, 0.3)',
        borderColor: '#444',
      },
      _disabled: {
        background: 'linear-gradient(135deg, #f0f0f0, #004080)', 
        color: '#555', 
      }
    }),
  }
};
