import { CHAIN_MAIN } from '~/constants';
import { montserrat, rubik, intertight } from '~/app/components/fonts/fonts';
import { Metadata } from 'next';
import { chainNavConfigs, NavItem } from './chainNav';
import { chainTickerConfigs, TickerToken } from './chainTicker';

interface ChainConfig {
  font: string;
  topLoader: string;
  showPoolActions: boolean;
  name: string;
  template: string;
  description: string;
  faviconPath: string;
  navItems: NavItem[];
  dropdownItems: NavItem[];
  tickerTokens: TickerToken[];

}

const chainConfigs: { [key: number]: ChainConfig } = {
  8453: {
    font: intertight.className,
    topLoader: '#00ffff',
    showPoolActions: true,
    name: 'BaseSwap',
    template: '%s | BaseSwap',
    description: 'BaseSwap is a leading decentralized exchange (DEX), onchain with Base Network.',
    faviconPath: '/faviconsBase',
    navItems: chainNavConfigs[8453].navItems, 
    dropdownItems: chainNavConfigs[8453].dropdownItems,
    tickerTokens: chainTickerConfigs[8453],



  },
  10: {
    font: rubik.className,
    topLoader: 'linear-gradient(to right, #FFB3B3, #FF6666)',
    showPoolActions: false,
    name: 'SuperSwap',
    template: '%s | SuperSwap',
    description: 'SuperSwap is a leading decentralized exchange (DEX), onchain with Optimism Network.',
    faviconPath: '/faviconsOptimism',
    navItems: chainNavConfigs[10].navItems, 
    dropdownItems: chainNavConfigs[10].dropdownItems,
    tickerTokens: chainTickerConfigs[10],


  },
  34443: {
    font: intertight.className,
    topLoader: '#dffe00',
    showPoolActions: true,
    name: 'SwapMode',
    template: '%s | SwapMode',
    description: 'SwapMode is a leading decentralized exchange (DEX), onchain with Mode Network.',
    faviconPath: '/faviconsMode',
    navItems: chainNavConfigs[34443].navItems, 
    dropdownItems: chainNavConfigs[34443].dropdownItems,
    tickerTokens: chainTickerConfigs[34443],
  },
};

const defaultConfig: ChainConfig = chainConfigs[8453];

export const chainConfig = chainConfigs[CHAIN_MAIN] || defaultConfig;
export const fontSelector = chainConfig.font;
export const topLoaderSelector = chainConfig.topLoader;
export const shouldShowPoolActions = () => chainConfig.showPoolActions;
export const getMetadata = (): Metadata => ({
  title: {
    default: chainConfig.name,
    template: chainConfig.template,
  },
  description: chainConfig.description,
});
export const getFaviconPath = () => chainConfig.faviconPath;
export const getNavItems = () => chainConfig.navItems;
export const getDropdownItems = () => chainConfig.dropdownItems; 
export const getTickerTokens = () => chainConfig.tickerTokens;

