'use client';

import React, { createContext, useState, useContext, useEffect } from 'react';
const NavVisibilityContext = createContext(null);
export const NavVisibilityProvider = ({
  children
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  // the numeric value above represents the pixel value of scroll position,
  // i.e. if set to 100, the navbar will start to animate out of view once the current
  // scroll position is greater than 100 pixels from the top of the page.

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY < lastScrollY || currentScrollY < 40) {
      setIsVisible(true);
    } else if (currentScrollY > 50 && currentScrollY > lastScrollY) {
      setIsVisible(false);
    }
    setLastScrollY(currentScrollY);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);
  return <NavVisibilityContext.Provider value={{
    isVisible
  }} data-sentry-element="unknown" data-sentry-component="NavVisibilityProvider" data-sentry-source-file="NavVisibilityContext.tsx">{children}</NavVisibilityContext.Provider>;
};
export const useNavVisibility = () => {
  const context = useContext(NavVisibilityContext);
  if (context === null) {
    throw new Error('use this within a navvisibility provider');
  }
  return context;
};