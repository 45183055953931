import { useMemo } from 'react';
import {
  ApolloClient,
  ApolloLink,
  concat,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { ChainId, SUBGRAPH_URL_MAP, V2_SUBGRAPH_URL_MAP } from '@baseswapfi/sdk-core';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { CHAIN_MAIN } from '~/constants';

const GQL_CHAINS: number[] = [CHAIN_MAIN];
type GqlChainsType = (typeof GQL_CHAINS)[number];

export function isGqlSupportedChain(chainId: number | undefined): chainId is GqlChainsType {
  return !!chainId && GQL_CHAINS.includes(chainId);
}

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<any>;

const userMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    return {
      headers: {
        ...headers,
      },
    };
  });

  return forward(operation);
});

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: concat(userMiddleware, new HttpLink({ uri: process.env.NEXT_PUBLIC_GQL_BACKEND_URL })),

    cache: new InMemoryCache(),
    queryDeduplication: true,
  });
}

export function initializeApolloClient(initialState: any = null) {
  try {
    const _apolloClient = apolloClient ?? createApolloClient();
    if (initialState) {
      const existingCache = _apolloClient.extract();
      const data = merge(existingCache, initialState, {
        arrayMerge: (destinationArray, sourceArray) => [
          ...sourceArray,
          ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
        ],
      });
      _apolloClient.cache.restore(data);
    }
    if (typeof window === 'undefined') return _apolloClient;
    if (!apolloClient) apolloClient = _apolloClient;

    return _apolloClient;
  } catch (error) {
    console.log(error);
  }
}

export async function loadApolloState({
  client,
  props = {},
  pageSetup,
  revalidate = 1,
}: {
  client: ApolloClient<any>;
  props?: any;
  pageSetup?: () => Promise<void>;
  revalidate?: number;
}) {
  // await client.query({ query: GetAppGlobalData });
  if (pageSetup) {
    await pageSetup();
  }
  return {
    props: {
      ...props,
      [APOLLO_STATE_PROP_NAME]: client.cache.extract(),
    },
    revalidate,
  };
}

export function useApollo(input: { [APOLLO_STATE_PROP_NAME]?: any } | null = null) {
  let initialState: { [APOLLO_STATE_PROP_NAME]?: any } | null = null;
  if (input && input.hasOwnProperty(APOLLO_STATE_PROP_NAME)) {
    initialState = input;
  } else {
    initialState = null;
  }

  return useMemo(() => initializeApolloClient(initialState), [initialState]);
}

export const chainToApolloV3Client: Record<number, ApolloClient<NormalizedCacheObject>> = {
  [ChainId.BASE]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: SUBGRAPH_URL_MAP[ChainId.BASE],
  }),

  [ChainId.MODE]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: SUBGRAPH_URL_MAP[ChainId.MODE],
  }),
  [ChainId.OPTIMISM]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: SUBGRAPH_URL_MAP[ChainId.OPTIMISM],
  }),
};
export const chainToApolloV2Client: Record<number, ApolloClient<NormalizedCacheObject>> = {
  [ChainId.BASE]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: V2_SUBGRAPH_URL_MAP[ChainId.BASE],
  }),

  [ChainId.MODE]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: V2_SUBGRAPH_URL_MAP[ChainId.MODE],
  }),
  [ChainId.OPTIMISM]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: V2_SUBGRAPH_URL_MAP[ChainId.OPTIMISM],
  }),
};
