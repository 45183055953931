import { Text, useTheme, BoxProps } from '@chakra-ui/react';
import React, { memo, ReactNode, forwardRef } from 'react';
interface NavbarLinkProps extends BoxProps {
  selected?: boolean;
  isActive?: boolean;
  children?: ReactNode;
}
const NavbarLink = memo(forwardRef<HTMLDivElement, NavbarLinkProps>(({
  isActive,
  selected = false,
  children,
  ...rest
}, ref) => {
  const theme = useTheme();
  return <Text ref={ref} {...rest} cursor="pointer" textAlign="center" userSelect="none" fontWeight={selected ? '500' : '500'} color={selected ? 'highlight' : 'text.100'} zIndex={1} fontSize={{
    base: '24px',
    lg: '16px'
  }} _hover={{
    color: 'text.100',
    textDecoration: 'underline'
  }} _active={{
    color: 'text.100'
  }}>
          {children}
        </Text>;
}));
NavbarLink.displayName = 'NavbarLink';
export default NavbarLink;