import { ModeNetworkConfig } from './mode-config';
import { BaseNetworkConfig } from './base-config';
import { NetworkConfig } from './network-config-type';
import { CHAIN_MAIN } from '~/constants';
import { OptimismNetworkConfig } from './optimism-config';

export const AllNetworkConfigs: { [chainId: number]: NetworkConfig } = {
  [ModeNetworkConfig.chainId]: ModeNetworkConfig,
  [BaseNetworkConfig.chainId]: BaseNetworkConfig,
  [OptimismNetworkConfig.chainId]: OptimismNetworkConfig,
};

//  const defaultNetworkConfig = ModeNetworkConfig;
//  const networkConfigs = [ModeNetworkConfig];

export const networkList = [
  {
    name: 'Optimism Network',
    chainId: 10,
    //HOMELESS** come back to this for OPTIMISM
    url: 'https://www.superswap.fi',
    iconUrl: OptimismNetworkConfig.iconUrl,
  },
  {
    name: 'Mode Network',
    chainId: 34443,
    url: 'https://www.swapmode.fi',
    iconUrl: ModeNetworkConfig.iconUrl,
  },
  {
    name: 'Base Network',
    chainId: 8453,
    url: 'https://www.baseswap.fi',
    iconUrl: BaseNetworkConfig.iconUrl,
  },
].filter((network) => network.chainId === CHAIN_MAIN);
