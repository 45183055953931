import LockerSimple from "~/app/components/icons/Simple/LockerSimple";
import BridgeSimple from "~/app/components/icons/Simple/BridgeSimple";


export interface NavItem {
 name: string;
 path: string;
 icon?: React.ComponentType<any>;
 isExternal?: boolean;
}

interface ChainNavConfig {
 navItems: NavItem[];
 dropdownItems: NavItem[]; 
}

const globalNavItems: NavItem[] = [
 { name: 'Swap', path: '/swap' },
 { name: 'Pools', path: '/pools' },
 { name: 'Staking', path: '/staking' },
 { name: 'Dashboard', path: '/dashboard' },
];


export const chainNavConfigs: { [key: number]: ChainNavConfig } = {
 8453: {
   navItems: globalNavItems,
   dropdownItems: [
    { name: 'xBSX', path: '/xtoken' },
    { name: 'Bridge', path: '/bridge', icon: BridgeSimple },
    { name: 'Token Locker', path: '/locker', icon: LockerSimple },
  ],
},
10: {
 navItems: globalNavItems.filter(item => item.name !== 'Staking'),
 dropdownItems: [
 ],
},
34443: {
 navItems: globalNavItems,
 dropdownItems: [
   { name: 'xSMD', path: '/xtoken' },
   { name: 'Locker', path: '/locker', icon: LockerSimple }, 
   { name: 'Symbiosis Bridge', path: 'https://app.symbiosis.finance/swap?chainIn=Mode&chainOut=Base&tokenIn=ETH&tokenOut=ETH', icon: BridgeSimple, isExternal: true}, 
   { name: 'Mode Bridge', path: 'https://app.mode.network/early/', icon: BridgeSimple, isExternal: true}


 ],
},
};