import { globalButtons } from "../../global/buttons/mainbuttons";
const primary = '#DFFE00';

export const modeButtons = {
  variants: {
    universalpawg: (props) => ({
      ...globalButtons.variants.universalpawg(props), 
      background: 'linear-gradient(135deg, #000000 0%, #1a1a1a 100%)',
      color: '#dffe00',
      borderColor: '#dffe00', 
      boxShadow: '0 0 5px rgba(223, 254, 0, 0.3), 0 0 10px rgba(223, 254, 0, 0.2)',
      _hover: {
        background: 'linear-gradient(135deg, #1a1a1a 0%, #000000 100%)',
        boxShadow: '0 0 15px rgba(223, 254, 0, 0.5), 0 0 20px rgba(223, 254, 0, 0.3)',
        color: '#ffffff',
      },
      _disabled: {
        background: 'linear-gradient(135deg, #f0f0f0, #dffe00)', 
        color: '#555', 
        opacity: '0.4', 
      }
    }),
    
    cosmicpawg: (props) => ({
      ...globalButtons.variants.cosmicpawg(props), 
      background: 'linear-gradient(135deg, #c0c0c0 10%, #000000 100%)',
      color: '#fff',
      fontWeight: '400',
      border: '1px solid #c0c0c0',
      letterSpacing: '0.5px',
      borderColor: '#fff', 

      boxShadow: '0 0 5px rgba(192, 192, 192, 0.3), inset 0 0 5px rgba(192, 192, 192, 0.2)',
      _hover: {
        background: 'linear-gradient(135deg, #c0c0c0 30%, #000000 100%)',
        boxShadow: '0 0 15px rgba(192, 192, 192, 0.4), inset 0 0 8px rgba(192, 192, 192, 0.3)',
        borderColor: '#dffe00',
      },
      _disabled: {
        background: 'linear-gradient(135deg, #000000, #dffe00)', 
        color: '#777', 
        opacity: '0.4', 
      }
    }),
  }
};
