import { BaseNetworkConfig } from '~/lib/config/base-config';
import { ModeNetworkConfig } from '~/lib/config/mode-config';
import { OptimismNetworkConfig } from '~/lib/config/optimism-config';

export interface TickerToken {
  name: string;
  address: string;
  symbol: string;
  format: string;
  amount: string;
}

export const chainTickerConfigs: { [chainId: number]: TickerToken[] } = {
  8453: [
   {
    name: 'Wrapped Bitcoin',
    address: BaseNetworkConfig.tokens.wbtc,
    symbol: 'WBTC',
    format: '$0,0',
    amount: '1',
  },
    {
      name: 'Ethereum',
      address: BaseNetworkConfig.eth.address,
      symbol: 'ETH',
      format: '$0,0',
      amount: '1',
    },
    {
     name: BaseNetworkConfig.secondProtocolToken.symbol,
     address: BaseNetworkConfig.secondProtocolToken.address,
     symbol: BaseNetworkConfig.secondProtocolToken.symbol,
     format: '$0.00[0]',
     amount: '1',
   },
    {
      name: BaseNetworkConfig.protocolToken.symbol,
      address: BaseNetworkConfig.protocolToken.address,
      symbol: BaseNetworkConfig.protocolToken.symbol,
      format: '$0.00[0]',
      amount: '1',
    },
  
  ],

  10: [
    {
      name: 'Ethereum',
      address: OptimismNetworkConfig.eth.address,
      symbol: 'ETH',
      format: '$0,0',
      amount: '1',
    },
    {
     name: 'Wrapped Bitcoin',
     address: OptimismNetworkConfig.tokens.wbtc,
     symbol: 'WBTC',
     format: '$0,0',
     amount: '1',
   },
   {
    name: 'OP',
    address: OptimismNetworkConfig.tokens.op,
    symbol: 'OP',
    format: '$0.00',
    amount: '1',
  },
  ],

  34443: [
    {
      name: 'Ethereum',
      address: ModeNetworkConfig.eth.address,
      symbol: 'ETH',
      format: '$0,0',
      amount: '1',
    },
    {
      name: ModeNetworkConfig.protocolToken.symbol,
      address: ModeNetworkConfig.protocolToken.address,
      symbol: ModeNetworkConfig.protocolToken.symbol,
      format: '$0.00[0]',
      amount: '1',
    },
    {
      name: 'Mode Token',
      address: ModeNetworkConfig.tokens.mode,
      symbol: 'MODE',
      format: '$0.00[0]',
      amount: '1',
    },
  ],
};

export const getChainTickerTokens = (chainId: number): TickerToken[] => {
  return chainTickerConfigs[chainId] || [];
};
