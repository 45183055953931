'use client';

import React from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { NavbarContent } from '~/app/components/nav/NavbarContent';
import { SubNavBar } from '~/app/components/nav/NavbarContent/SubNavBar';
import { staggeredFadeIn, fadeIn } from '../animated/lib/animations';
import { useNavVisibility } from './NavbarContent/NavVisibilityContext';
const MotionBox = props => <Box as={motion.div} {...props} data-sentry-element="Box" data-sentry-component="MotionBox" data-sentry-source-file="Navbar.tsx" />;
const MotionVStack = props => <VStack as={motion.div} {...props} data-sentry-element="VStack" data-sentry-component="MotionVStack" data-sentry-source-file="Navbar.tsx" />;
interface NavbarProps {
  children: React.ReactNode;
}
const Navbar: React.FC<NavbarProps> = ({
  children,
  ...props
}) => {
  const {
    isVisible
  } = useNavVisibility();
  return (
    // the duration below on the transition prop on the parent motion box is the length of the animation of it 'circing' out.

    <>
      <MotionBox position="fixed" top="0" w="100%" zIndex="20" initial={{
        y: 0
      }} animate={{
        y: isVisible ? 0 : '-100%'
      }} transition={{
        ease: 'circOut',
        duration: 0.5
      }} data-sentry-element="MotionBox" data-sentry-source-file="Navbar.tsx">
        <MotionVStack w="full" spacing="0" align="stretch" variants={staggeredFadeIn} initial="hidden" animate="show" data-sentry-element="MotionVStack" data-sentry-source-file="Navbar.tsx">
          <MotionBox variants={fadeIn} data-sentry-element="MotionBox" data-sentry-source-file="Navbar.tsx">{children}</MotionBox>
        </MotionVStack>
      </MotionBox>
    </>
  );
};
export default Navbar;