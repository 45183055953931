export const universalLight = {

alphaGradient: {
  100: 'linear-gradient(135deg, #f5f5f0, #EFEDE6)',
  200: 'linear-gradient(135deg, #E8E6DE, #F0EEE6)', 
}, 

darkborder: '#ccc', 

popoverButtonText: '#666', 
tableborder: '#fff', 
tablebg: '#F5F3EF', 
layoutbg: '#f5f5f0', 


toggleGradient: { 
  100: 'linear-gradient(135deg, rgba(255,255,255,0.9), rgba(255,255,255,0.7))',
  200: 'linear-gradient(135deg, rgba(255,255,255,0.8), rgba(255,255,255,0.6))',
  300: 'linear-gradient(135deg, rgba(255,255,255,0.3), rgba(255,255,255,0.2))', 
  400: 'linear-gradient(135deg, rgba(255,255,255,0.2), rgba(255,255,255,0.1))', 
 }, 

text: {
  100: '#2D3848',
  200: '#020202',
  300: '#191919',
  400: '#333',
  450: '#5e5e5e',
  500: '#7f7f7f',
  600: '#9A9AA6',
  650: '#BBB',
  700: '#d4d4d4',
  750: '#e7e7e7',
  800: '#eeeeee',
  900: '#ffffff',
},
textAlpha: {
  100: 'rgba(0, 0, 0, 0.1)',
  200: 'rgba(0, 0, 0, 0.2)',
  300: 'rgba(0, 0, 0, 0.3)',
  400: 'rgba(0, 0, 0, 0.4)',
  500: 'rgba(0, 0, 0, 0.5)',
  600: 'rgba(0, 0, 0, 0.6)',
  700: 'rgba(0, 0, 0, 0.7)',
  800: 'rgba(0, 0, 0, 0.8)',
  900: 'rgba(0, 0, 0, 0.9)',
}, 
gray: {
  '50': '#191919',
  '100': '#191919',
  '200': '#2C2C2C',
  '300': '#404040',
  '400': '#535353',
  '500': '#666666',
  '550': '#8D8D8D',
  '600': '#7A7A7A',
  '700': '#bbb',
  '750': '#BBB',
  '800': '#C7C7C7',
  '850': '#D3D3D3',
  '900': '#E7E7E7',
  '950': '#FAFAFA',
},
priceline: {
'100': '#191919'
}, 
}
