
export const globalShadows = {
  light: {
    //buttons
    tokenInputSwitch: '0.1rem 0.1rem 0.1rem 0px rgba(255, 255, 255, 0.5) inset, -0.1rem -0.1rem 0.1rem 0px rgba(0, 0, 0, 0.15) inset, 0.1rem 0.1rem 0.1rem 0px rgba(0, 0, 0, 0.07) !important',
   
    insetComponentShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.15), inset 0 4px 8px rgba(0, 0, 0, 0.1)', 
    outsetComponentShadow: '0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.05)', 

    
    primaryButtonShadow: '0.1rem 0.1rem 0.1rem 0px rgba(255, 255, 255, 0.5) inset, -0.1rem -0.1rem 0.1rem 0px rgba(0, 0, 0, 0.15) inset, 0.1rem 0.1rem 0.1rem 0px rgba(0, 0, 0, 0.07) !important',
    //general
    dexShadow: '0 0 96px -48px #fff, 0 0 48px -24px #fff',
    md: '0px 0px 0px 1px #49351D05, 1px 1px 1px -0.5px #49351D0F, 3px 3px 3px -1.5px #49351D0F, 6px 6px 6px -3px #49351D0F, -0.5px -0.5px 0px 0px #FFFFFF',
    lifted: '0 2px 2px #333, -2px 0 0 1px #fff', 
    //components
    pooltable: '4px 0 12px rgba(0, 0, 0, 0.2)', 
    input: {
      unfocused: 'inset 0 2px 4px rgba(0, 0, 0, 0.1), inset 0 -1px 1px rgba(255, 255, 255, 0.1) !important',
      focused: 'inset 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(255, 255, 255, 0.1) !important',
    }, 
    popover: { 
      xs: 'inset 0 2px 4px rgba(0, 0, 0, 0.15), inset 0 -2px 4px rgba(0, 0, 0, 0.08) !important',
      dropShadow: '0 8px 16px rgba(0, 0, 0, 0.75), 0 2px 4px rgba(0, 0, 0, 0.1) !important',
    }, 
    insetComponent1: 
        `0px 0px 0px 1px #49351D05, 1px 1px 1px -0.5px #49351D0F, 
        3px 3px 3px -1.5px #49351D0F, 6px 6px 6px -3px #49351D0F, 
        12px 12px 12px -6px #49351D0F, 24px 24px 24px -12px #49351D0F, 
        42px 42px 42px -24px #49351D0F, -0.5px -1px 0px 0px #FFFFFF`,
    insetComponent2: 
        `4px 4px 6px 0px rgba(0, 0, 0, 0.06) inset, 
        7px 6px 14px 0px rgba(0, 0, 0, 0.1) inset, 
        40px 40px 80px 0px rgba(0, 0, 0, 0.05) inset, 
        0px -1px 1px 0px rgba(255, 255, 255, 0.25) inset,
        0px 1px 2px rgba(0, 0, 0, 0.05)`,
  },
  
  dark: {
    tokenInputSwitch: '1px 1px 1px 0px rgba(255, 255, 255, 0.15) inset, -1px -1px 1px 0px rgba(0, 0, 0, 0.15) inset, 0.1rem 0.1rem 0.1rem 0px rgba(0, 0, 0, 0.15) !important',
    insetComponentShadow: '20px 20px 50px 0px rgba(0, 0, 0, 0.25) inset, 10px 10px 25px 0px rgba(0, 0, 0, 0.18) inset, 2px 2px 11px 0px rgba(0, 0, 0, 0.19) inset, 0px 0px 1px 0px #FFFFFF40 inset', 
    outsetComponentShadow: '20px 20px 50px 0px rgba(0, 0, 0, 0.25), 10px 10px 25px 0px rgba(0, 0, 0, 0.18), 2px 2px 11px 0px rgba(0, 0, 0, 0.19), 0px -1px 1px 0px #FFFFFF40', 

    primaryButtonShadow: '0 0 8px rgba(10, 10, 10, 1)',

    //general 
    md: '0px 0px 0px 1px #00000005, 1px 1px 1px -0.5px #0000000F, 3px 3px 3px -1.5px #0000000F, 6px 6px 6px -3px #0000001A, -0.5px -0.5px 0px 0px #FFFFFF33',
    lifted: '0 0 12px #000', 
    dexShadow: '0 0 96px -48px #fff, 0 0 48px -24px #fff',
    
    //components
    pooltable: '4px 0 24px var(--chakra-colors-highlight)', 
    input: {
      unfocused: 'inset 0 1px 2px rgba(0, 0, 0, 0.5) !important',
      focused: 'inset 0 2px 4px rgba(0, 0, 0, 0.5) !important',
    }, 
    popover: {
      dropShadow: '0 8px 24px rgba(10, 10, 10, 1), 0 2px 4px rgba(0, 0, 0, 0.1) !important',
      arrow: '1px 1px 3px rgba(0, 0, 0, 0.1) !important', 
      xs: 
          `inset 0 2px 4px rgba(0, 0, 0, 0.5), 
          inset 0 -2px 4px rgba(0, 0, 0, 0.5),
          inset 4px 4px 8px rgba(0, 0, 0, 0.5),
          inset -4px -4px 8px rgba(0, 0, 0, 0.5) !important`,
    }, 
    insetComponent1: 
          `0px 0px 0px 1px #49351D05, 1px 1px 1px -0.5px #49351D0F, 
          3px 3px 3px -1.5px #49351D0F, 6px 6px 6px -3px #49351D0F, 
          12px 12px 12px -6px #49351D0F, 24px 24px 24px -12px #49351D0F, 
          42px 42px 42px -24px #49351D0F, -0.5px -1px 0px 0px #FFFFFF`,
    insetComponent2:  
          `4px 4px 6px 0px rgba(0, 0, 0, 0.2) inset, 
          7px 6px 14px 0px rgba(0, 0, 0, 0.25) inset, 
          40px 40px 80px 0px rgba(0, 0, 0, 0.15) inset, 
          0px 1px 1px 0px rgba(255, 255, 255, 0.05) inset,
          0px -1px 2px rgba(0, 0, 0, 0.2)`, 
  }
};


